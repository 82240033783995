import React from 'react'
import LandingPage from "LandingPageMaterial/Layout/LandingPage"
import { useForm } from "react-hook-form"
import dayjs from 'dayjs'
import GridContainer from '../components/material-kit-pro-react/components/Grid/GridContainer'
import GridItem from '../components/material-dashboard-pro-react/components/Grid/GridItem'
import DateMaterialPickerController from '../components/Core/Controller/DateMaterialPickerController'
import InputController from '../components/Core/Controller/InputController'
import SelectSimpleController from '../components/Core/Controller/SelectSimpleController'
import EmailController from '../components/Core/Controller/EmailController'
import PhoneController from '../components/Core/Controller/PhoneController'
import { Button, Typography } from '@mui/material'
import CardPanel from '../components/Core/Card/CardPanel'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import SearchIcon from "@mui/icons-material/Search"

const Experiencia_usuario = () => {
    const { handleSubmit, control, ...objForm } = useForm()

    const date = dayjs(new Date())
    const dateEnd = date
    const dateStart = date.add(-1, 'month')

    const onSubmit = async (data) => {
        console.log(data, 'dataa')

    }
    return (
        <LandingPage color="white" pageTitle="Realización de Pagos">
            <div style={{ margin: "8rem 6rem 6rem 6rem" }} >
                <CardPanel titulo="Solicitud Actualización Datos"
                    icon={<DriveFileRenameOutlineIcon />}
                >
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <GridContainer style={{
                                padding: "20px",
                                margin: "14px",
                                width: "98%",
                                borderRadius: "10px",
                                boxShadow: " 4px 17px 17px -3px rgba(0,0,0,0.1)"
                            }}>
                                <GridItem item xs={4} sm={6} md={6} lg={6}>
                                    <InputController
                                        control={control}
                                        label="Nombre"
                                        name={`p_name_one`}
                                    />
                                </GridItem>
                                <GridItem item xs={4} sm={6} md={6} lg={6}>
                                    <InputController
                                        control={control}
                                        label="Apellido"
                                        name={`p_name_one`}
                                    />
                                </GridItem>
                                <GridItem item xs={4} sm={4} md={4} lg={4}>
                                    <DateMaterialPickerController
                                        fullWidth
                                        control={control}
                                        label="Fecha Nacimiento"
                                        name="start_date"
                                        defaultValue={dateStart}
                                        required={false}
                                    />
                                </GridItem>
                                <GridItem item xs={4} sm={4} md={4} lg={4}>
                                    <PhoneController
                                        control={control}
                                        label="Teléfono"
                                        name={`p_telefono_tit`}
                                    />
                                </GridItem>
                                <GridItem item xs={4} sm={4} md={4} lg={4}>
                                    <EmailController
                                        control={control}
                                        label="Correo"
                                        name="p_cc"
                                        required={false}
                                    />
                                </GridItem>
                                <GridItem item xs={4} sm={12} md={12} lg={12}>
                                    <SelectSimpleController
                                        required={true}
                                        control={control}
                                        name={`Pregunta_1`}
                                        array={[
                                            { value: "si", description: "Si" },
                                            { value: "no", description: "No" }
                                        ]}
                                        label={"¿Eres Asegurado de Oceánica de Seguros?"}
                                    />
                                </GridItem>

                                <GridItem item xs={4} sm={6} md={6} lg={6}>
                                    <SelectSimpleController
                                        required={true}
                                        control={control}
                                        name={`Pregunta_2`}
                                        array={[
                                            { value: "si", description: "Si" },
                                            { value: "no", description: "No" },
                                            { value: "parcialmente", description: "Parcialmente" }
                                        ]} label={"¿Este Chat te ayudó a obtener la información deseada?"}
                                    />
                                </GridItem>

                                <GridItem item xs={4} sm={6} md={6} lg={6}>
                                    <SelectSimpleController
                                        required={true}
                                        control={control}
                                        name={`Pregunta_3`}
                                        array={[
                                            { value: "excelente", description: "Excelente" },
                                            { value: "bueno", description: "Bueno" },
                                            { value: "regular", description: "Regular" },
                                            { value: "insuficiente", description: "Insuficiente" }
                                        ]} label={"¿Cómo calificas nuestro chat?"}
                                    />
                                </GridItem>



                                <GridItem style={{ marginTop: "3rem" }} item xs={12} sm={12} md={12} lg={12}>
                                    <Button type="submit" fullWidth variant="contained" sx={{ color: "white", fontSize: "11px" }} size="small" round>
                                        <Typography color="white.main">
                                            Actualizar
                                        </Typography>
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </form>

                    </div>
                </CardPanel>
            </div>
        </LandingPage>

    )
}

export default Experiencia_usuario